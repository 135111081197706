import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import {
  Layout,
  SEO,
  Hero,
  ImageWithText,
  Services,
  WhyWorkWithUs,
  GoOverYourProject,
  OurClients,
  Contact,
  Map,
  SuitsOverlay,
} from '../../components';
import { useServices } from '../../data';

function ProjectManagementPage() {
  const title = 'Project Management';

  const services = useServices().filter((s) => s.title !== title);

  const service = useServices().filter((s) => s.title === title);

  const Icon = service[0].icon;

  const { hero, feature } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "project-management-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature: file(relativePath: { eq: "project-management-feature.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          shortName
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Project Management" />
      <Hero
        image={hero.childImageSharp.fluid}
        heading={
          <>
            <Icon className="mx-auto text-white w-28 h-28" />
            <span className="inline-block mt-8">{title}</span>
          </>
        }
      />
      <SuitsOverlay />
      <ImageWithText
        marginTop
        image={feature.childImageSharp.fluid}
        heading="Designed to Suit Your Venue"
        copy={service[0].description}
      />
      <GoOverYourProject />
      <WhyWorkWithUs />
      <Services services={services} />
      <OurClients />
      <Contact />
      <Map />
    </Layout>
  );
}

ImageWithText.propTypes = {
  image: PropTypes.object.isRequired,
};

export default ProjectManagementPage;
